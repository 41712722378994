<template>
    <list-condition-template ref="table" :rows="rows" :total="total"
     @page-info-change="handlePageInfoChange" :defaultSort="{prop:'updateTime',order:'descending'}"
      v-model:listQuery="listQuery" :index-column="true">
    <template #condition>
      <el-form-item>
        <div class="demo-date-picker">
          <div class="block">
            <span class="demonstration">筛选日期：</span>
            <el-date-picker
              clearable
              value-format="YYYY-MM-DD"
              v-model="valueDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
          <el-select @change="handleFilter" clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="订单状态" v-model="listQuery.condition.orderStatus">
            <el-option v-for="item in statusOptions" :key="item.name" :label="item.type" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="输入订单批次查询" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
      <el-row>
        <el-button class="filter-item" @click="addOrderClick" type="success" size="mini" icon="el-icon-plus" v-waves>新建</el-button>
      </el-row>
    </template>
    <template #columns>
        <el-table-column min-width="90px" label="单据类型" align="center">标签采购单</el-table-column>
        <el-table-column min-width="90px" label="订单状态" align="center">
           <template #default="scope">
            <el-tag :type="showFilter(scope.row)">{{scope.row.orderStatus?scope.row.orderStatus.type:''}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="制单时间" align="center" property="orderTime">
          <template  #default="scope">
            <span>{{scope.row.orderTime}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" label="制单人账号" property="createBy" align="center">
          <template #default="scope">
            <span>{{scope.row.createBy}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="80px" label="防伪码标签（套）" property="securityNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.securityNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" label="订单批次" property="orderBatch" align="center">
          <template #default="scope">
            <span>{{scope.row.orderBatch}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="标签供应商" property="supplier.name" align="center">
          <template #default="scope">
            <span>{{scope.row.supplier.name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="300px" class-name="fixed-width">
          <template  #default="scope">
            <el-button size="mini" type="primary" @click="handleShow(scope.row)" v-waves>查看</el-button>
            <el-button size="mini" type="success" @click="handleTransact(scope.row)" v-waves>办理</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-waves>删除</el-button>
          </template>
        </el-table-column>
    </template>
    <el-dialog v-model="startupFormDialogShow" :append-to-body="true" width="80%">
        <component :is="startupForm" v-model:showDialog="startupFormDialogShow" v-model:orderId="orderId" @close="startupFormDialogShow=false;getList()"></component>
    </el-dialog>

  </list-condition-template>
</template>

<script>
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'
import orderTempApi from '@/apis/order/orderTemp'
import addBillsForm from './addBills'
export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate,
    addBillsForm
  },
  data () {
    return {
      statusOptions: [
        {
          name: 'READY_ORDER',
          type: '申请中'
        },
        {
          name: 'CANCEL',
          type: '已取消'
        }
      ],
      valueDate: undefined,
      rows: null,
      total: 0,
      orderId: undefined,
      startupFormDialogShow: false,
      startupForm: null,
      listQuery: {
        condition:{
          keyword: null,
          enabled: null,
          startTime: undefined,
          endTime: undefined
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20
        }
      }
    }
  },
  filters: {
  },
  methods: {
    showFilter(row){
      if(row.orderStatus && row.orderStatus.name === 'READY_ORDER'){
        return 'warning'
      }else if(row.orderStatus && row.orderStatus.name === 'SHIPPED'){
        return 'success'
      }else if(row.orderStatus && row.orderStatus.name === 'RECEIVE'){
        return 'info'
      }else if(row.orderStatus && row.orderStatus.name === 'CANCEL'){
        return 'danger'
      }else{
        return ' '
      }
    },
    handlePageInfoChange () {
      this.getList()
    },
    getList () {
      orderTempApi
        .getPaged(this.listQuery)
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    handleShow(row){
      this.$router.push({path:`/business/cooperation/purchase/billsDetails/${row.id}`, query: { source:'holdBills'}})
    },
    /**
     * 搜索
     */
    handleFilter () {
      if(this.valueDate){
        this.listQuery.condition.startTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.endTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.startTime = undefined
        this.listQuery.condition.endTime = undefined
      }
      this.getList()
    },
    addOrderClick(){
      this.startupForm = 'addBillsForm'
      this.startupFormDialogShow = true
      this.orderId = null
    },
    handleTransact(row){
      //跳转到办理页面
      this.startupForm = 'addBillsForm'
      this.startupFormDialogShow = true
      this.orderId = row.id
    },
    handleDelete(row){
      this.$confirm('删除后无法恢复,确定要删除订单吗？', '警告', {
        type: 'warning'
      }).then(() => {
        orderTempApi.delete(row.id).then(res => {
          if (res.data) {
            this.$message.success("删除成功")
            this.getList()
          } else {
            this.$message.error({
              title: '错误',
              message: res.msg === '' ? '删除订单失败，请稍后重试！！！' : res.msg,
              duration: 0,
              position: 'bottom-right'
            })
          }
        })
      })
    }
  }
}
</script>
